<script setup lang="ts">
import { useAuthStore } from "@/store/auth";
import { useEmployerStore } from "@/store/employer";
import NotificationsDropdown from "@/components/dashboard/notifications/NotificationsDropdown.vue";

interface Emit {
  (e: "toggleMobileSide", value: boolean): void;
}

defineEmits<Emit>();

const authStore = useAuthStore();
const { employer } = storeToRefs(useEmployerStore());

async function signOut() {
  await authStore.signOut();
  navigateTo(ROUTES.login);
}
</script>

<template>
  <div class="sticky top-0 px-4 pt-4 sm:px-6 sm:pt-6 rounded-b-full bg-white-gray dark:bg-dark-gray z-40">
    <div class="rounded-full box-border flex shrink-0 items-center gap-x-4 sm:gap-x-6 bg-primary shadow-sm p-4 sm:p-5">
      <button type="button" class="-m-2.5 p-2.5 text-dark-gray xl:hidden" @click="$emit('toggleMobileSide', true)">
        <span class="sr-only">Open sidebar</span>
        <Icon name="fa6-solid:bars" class="h-6 w-6 text-white" aria-hidden="true" />
      </button>
      <CreditsCTA class="hidden sm:flex" reduced />

      <div class="flex gap-x-4 self-stretch ml-auto xl:gap-x-6">
        <div class="flex items-center gap-x-4 xl:gap-x-6">
          <NotificationsDropdown />

          <div class="hidden xl:block xl:h-6 xl:w-px xl:bg-white" aria-hidden="true" />

          <DropdownMenu v-if="employer">
            <template #dropdown-button>
              <span class="sr-only">Open user menu</span>
              <UserAvatar size="h-8 w-8" :img="employer.profile_image" :alt="employer.first_name" />
            </template>
            <template #dropdown-items>
              <DropdownItem :href="ROUTES.dashboardAccountSettings" icon="fa6-solid:gear">Settings</DropdownItem>
              <DropdownItem @click="signOut()" icon="akar-icons:sign-out">Sign out</DropdownItem>
            </template>
          </DropdownMenu>
        </div>
      </div>
    </div>
  </div>
</template>
